import Router from "next/router";

import TextButton from "@sellernote/_sds-v2/src/components/button/TextButton";

import { Trans, useTranslation } from "../../../../../../../utils/i18n";

import Management from "../components/Management";
import NewcomerGuide from "../components/NewcomerGuide";
import Styled from "../components/styles/index.styles";

import { ShipdaService } from "../..";

export default function ManagerSectionForDesktop({
  serviceType,
}: {
  serviceType: ShipdaService;
}) {
  const { t } = useTranslation(["common-new"]);

  return (
    <Styled.container>
      <NewcomerGuide serviceType={serviceType} />

      <Styled.management>
        <Styled.title>
          [{t("담당자별연락처_운영")}]
          <Styled.subtitle>
            {" "}
            (
            <Trans
              i18nKey="common-new:푸터_담당자별연락처_담당매니저문구"
              t={t}
              components={{
                lnk: (
                  <TextButton
                    label={`[${t(
                      "푸터_담당자별연락처_담당매니저_마이페이지"
                    )}]`}
                    fontSize={12}
                    theme="gray"
                    handleClick={() => {
                      Router.push("/mypage");
                    }}
                    btnAreaPadding={{
                      top: 4,
                      bottom: 4,
                      right: 4,
                      left: 4,
                    }}
                  />
                ),
              }}
            />
            )
          </Styled.subtitle>
        </Styled.title>

        <Management serviceType={serviceType} />
      </Styled.management>
    </Styled.container>
  );
}
