import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { RADIX_COLOR } from "@sellernote/_sds-v2/src/styles/colors";

const AEOBanner = styled.div`
  // 디자인 요청
  .custom-alert-banner {
    background-color: ${RADIX_COLOR.green8};

    ${mobile(
      css`
        display: flex;
        flex-direction: column;
        gap: 4px;

        .alert-banner-label {
          margin-right: 0;
          white-space: nowrap;
        }
      `
    )}
  }
`;

export default {
  AEOBanner,
};
