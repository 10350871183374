import { SellernoteAppRegion } from "@sellernote/_shared/src/types/common/common";

import { Trans } from "../../../../../../../../utils/i18n";

import { ManagerInfoProps, ManagerType, ShipdaService } from "../../..";

const MANGER_RECORD: Record<
  SellernoteAppRegion,
  Record<
    ShipdaService,
    Record<
      Extract<ManagerType, "newcomerGuide" | "management">,
      ManagerInfoProps[]
    >
  >
> = {
  KR: {
    forwarding: {
      newcomerGuide: [
        {
          name: (
            <Trans i18nKey="common-new:포워딩_신규상담_담당자_이름_손석균" />
          ),
          phone: "02-6956-7213",
          email: "max@ship-da.com",
        },
        {
          name: (
            <Trans i18nKey="common-new:포워딩_신규상담_담당자_이름_정정훈" />
          ),
          phone: "02-6956-7218",
          email: "jhjeong@ship-da.com",
        },
      ],
      management: [
        {
          name: <Trans i18nKey="common-new:포워딩_운영_담당자_이름_박승혁" />,
          phone: "02-6956-7215",
          email: "hyeok@ship-da.com",
        },
        {
          name: <Trans i18nKey="common-new:포워딩_운영_담당자_이름_이보미" />,
          phone: "02-6956-7297",
          email: "bomi@ship-da.com",
        },
        {
          name: <Trans i18nKey="common-new:포워딩_운영_담당자_이름_이창준" />,
          phone: "02-6956-7483",
          email: "cj@ship-da.com",
        },
        {
          name: <Trans i18nKey="common-new:포워딩_운영_담당자_이름_전주리" />,
          phone: "02-6956-7210",
          email: "juri@ship-da.com",
        },
        {
          name: <Trans i18nKey="common-new:포워딩_운영_담당자_이름_채기호" />,
          phone: "02-6956-7232",
          email: "kyo@ship-da.com",
        },
        {
          name: <Trans i18nKey="common-new:포워딩_운영_담당자_이름_최혁중" />,
          phone: "02-6956-7277",
          email: "choihj@ship-da.com",
        },
        {
          name: <Trans i18nKey="common-new:포워딩_운영_담당자_이름_황동희" />,
          phone: "02-6956-7225",
          email: "hdh05171003@ship-da.com",
        },
        {
          name: <Trans i18nKey="common-new:포워딩_운영_담당자_이름_황호진" />,
          phone: "02-6956-7219",
          email: "hjhwang@ship-da.com",
        },
      ],
    },
    fulfillment: {
      newcomerGuide: [
        {
          name: (
            <Trans i18nKey="common-new:풀필먼트_신규상담_담당자_이름_주대룡" />
          ),
          phone: "02-6956-7266",
          email: "daeryong.ju@ship-da.com",
        },
      ],
      management: [
        {
          name: <Trans i18nKey="common-new:풀필먼트_운영_담당자_이름_유태경" />,
          phone: "02-6956-7235",
          email: "zgzg0218@ship-da.com",
        },
        {
          name: <Trans i18nKey="common-new:풀필먼트_운영_담당자_이름_윤세영" />,
          phone: "032-715-7891",
          email: "dbstpdud12@ship-da.com",
        },
      ],
    },
  },
  // TODO: 추가 예정 (준후님 기획팀과 논의 중)
  SG: {
    forwarding: {
      newcomerGuide: [
        {
          name: "Rick",
          phone: "",
          email: "rick@ship-da.com",
        },
        {
          name: "Jimmy",
          phone: "",
          email: "hjhwang@ship-da.com",
        },
      ],
      management: [],
    },
    fulfillment: {
      newcomerGuide: [],
      management: [],
    },
  },
};

export default MANGER_RECORD;
