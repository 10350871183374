import { ComponentProps, ReactNode } from "react";

import TextButton from "../button/TextButton";
import ChevronRightIcon from "../svgIcons/ChevronRightIcon";
import Styled from "./index.styles";

type AlertType = "default" | "Point";

export type { AlertType };

export default function AlertBanner({
  label,
  type = "default",
  textButtonProps,
  className = "",
}: {
  label: ReactNode;
  type?: AlertType;
  textButtonProps?: Omit<
    ComponentProps<typeof TextButton>,
    "iconInfo" | "hasUnderline"
  >;
  className?: string;
}) {
  return (
    <Styled.container className={`${className} alert-banner`} type={type}>
      {typeof label === "string" ? (
        <div className="alert-banner-label">{label}</div>
      ) : (
        label
      )}

      {textButtonProps && (
        <TextButton
          {...textButtonProps}
          iconInfo={{
            Icon: ChevronRightIcon,
            position: "right",
          }}
        />
      )}
    </Styled.container>
  );
}
