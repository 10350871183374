import { useTranslation } from "../../../../../../utils/i18n";

import { ShipdaService } from "..";
import Styled from "./index.styles";

export default function CustomTab({
  currentTab,
  onTabSelect,
}: {
  currentTab: "forwarding" | "fulfillment";
  onTabSelect: (v: ShipdaService) => void;
}) {
  const { t } = useTranslation(["common-new"]);

  return (
    <Styled.container>
      <Styled.forwardingTab
        isActive={currentTab === "forwarding"}
        onClick={() => onTabSelect("forwarding")}
      >
        {t("common-new:담당자별연락처_도메인_토글_포워딩")}
      </Styled.forwardingTab>

      <Styled.fulfillmentTab
        isActive={currentTab === "fulfillment"}
        onClick={() => onTabSelect("fulfillment")}
      >
        {t("common-new:담당자별연락처_도메인_토글_풀필먼트")}
      </Styled.fulfillmentTab>
    </Styled.container>
  );
}
