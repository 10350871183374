import React, { Dispatch, SetStateAction, useState } from "react";
import Router from "next/router";

import {
  APP_BUILD_INFO,
  IS_UNDER_PRODUCTION,
} from "@sellernote/_shared/src/constants";
import { useBodyScrollLock } from "@sellernote/_shared/src/hooks/common/useBodyScrollLock";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import Button from "@sellernote/_sds-v2/src/components/button/Button";
import Divide from "@sellernote/_sds-v2/src/components/Divide";
import MenuIcon from "@sellernote/_sds-v2/src/components/svgIcons/MenuIcon";
import SubmenuAnnouncementIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuAnnouncementIcon";
import SubmenuBookIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuBookIcon";
import SubmenuCalendarIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuCalendarIcon";
import SubmenuCodeIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuCodeIcon";
import SubmenuFlightIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuFlightIcon";
import SubmenuGiveMoneyIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuGiveMoneyIcon";
import SubmenuInternationalIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuInternationalIcon";
import SubmenuPlayerIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuPlayerIcon";
import SubmenuSurpriseIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuSurpriseIcon";
import SubmenuTruckIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuTruckIcon";
import SubmenuVesselIcon from "@sellernote/_sds-v2/src/components/svgIcons/SubmenuVesselIcon";
import UserFilledIcon from "@sellernote/_sds-v2/src/components/svgIcons/UserFilledIcon";
import XMarkDefaultIcon from "@sellernote/_sds-v2/src/components/svgIcons/XMarkDefaultIcon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { useTranslation } from "../../../../utils/i18n";

import SubmenuItem from "../components/SubmenuItem";

import Logo from "../..//Logo";
import Logout from "../..//Logout";
import User from "../..//User";
import MenuItem from "./MenuItem";
import Styled from "./index.styles";

const MobileDivide = () => {
  return (
    <Divide
      lineStyle="line"
      thickness={1}
      type="horizontal"
      width="100%"
      color={COLOR.grayScale_300}
    />
  );
};

export default function MobileHeader({
  loggedIn,
  setIsVisibleLoginModal,
}: {
  loggedIn: boolean;
  setIsVisibleLoginModal: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation(["common", "common-new"]);

  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
  const [activeMenuLabel, setActiveMenuLabel] = useState<string>();

  useBodyScrollLock({
    enablesLocking: isVisibleDrawer,
  });

  const handleAuthClick = () => {
    if (!loggedIn) return setIsVisibleLoginModal(true);

    Router.push("/mypage");
  };

  const handleMenuSelect = (label: string) => {
    setActiveMenuLabel(label);
  };

  const handleDrawerClose = () => {
    setIsVisibleDrawer(false);
  };

  return (
    <>
      <Styled.mobileHeader>
        <Logo type="header" />

        <Styled.authAndMenuContainer loggedIn={loggedIn}>
          <div className="auth-container">
            {loggedIn ? (
              <User />
            ) : (
              <UserFilledIcon
                width={24}
                height={24}
                color={loggedIn ? COLOR.grayScale_900 : COLOR.grayScale_700}
                onClick={handleAuthClick}
              />
            )}
          </div>

          <MenuIcon
            className="menu-icon"
            width={24}
            height={24}
            color={COLOR.grayScale_700}
            onClick={() => setIsVisibleDrawer(true)}
          />
        </Styled.authAndMenuContainer>
      </Styled.mobileHeader>

      {isVisibleDrawer && (
        <Styled.mobileDrawerContainer onClick={() => setIsVisibleDrawer(false)}>
          {!IS_UNDER_PRODUCTION && APP_BUILD_INFO && (
            <div className="build-info">
              {toFormattedDate(APP_BUILD_INFO.builtAt, "YYYY.MM.DD HH:mm:ss Z")}
            </div>
          )}

          <Styled.mobileAuthAndMenuContainer
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {!loggedIn && (
              <div className="auth-container">
                <Button
                  label={t("common-new:헤더_회원가입_버튼")}
                  theme="tertiary"
                  borderType="filled"
                  size="normal"
                  width="50%"
                  handleClick={() => {
                    Router.push("/register");
                  }}
                />

                <Button
                  label={t("common-new:헤더_로그인_버튼")}
                  size="normal"
                  theme="secondary"
                  borderType="filled"
                  width="50%"
                  handleClick={() => setIsVisibleLoginModal(true)}
                />
              </div>
            )}

            <nav className="menu-container">
              <ul>
                <MenuItem
                  label="홈"
                  path="/"
                  handleDrawerClose={handleDrawerClose}
                />

                <MenuItem
                  label="회사소개"
                  path="/company"
                  handleDrawerClose={handleDrawerClose}
                />

                <MenuItem
                  label="서비스"
                  onSelect={handleMenuSelect}
                  selected={activeMenuLabel === "서비스"}
                >
                  <ul onClick={handleDrawerClose}>
                    <Styled.submenuTitle>Forwarding</Styled.submenuTitle>

                    <SubmenuItem
                      label="해상운송"
                      desc="전세계 → 한국, 해상운임 견적의뢰"
                      path="/forwarding/ocean"
                      badges={["FCL", "LCL"]}
                      Icon={<SubmenuVesselIcon width={28} height={28} />}
                    />

                    <SubmenuItem
                      label="항공운송"
                      desc="전세계 → 한국, 항공운임 견적의뢰"
                      path="/forwarding/air"
                      badges={["AIR"]}
                      Icon={<SubmenuFlightIcon width={28} height={28} />}
                    />

                    <SubmenuItem
                      label="스케줄 조회"
                      desc="해운/항공사별 스케줄 조회"
                      path="/forwarding/schedule"
                      badges={["ALL"]}
                      Icon={<SubmenuCalendarIcon width={28} height={28} />}
                    />
                  </ul>

                  <MobileDivide />

                  <ul onClick={handleDrawerClose}>
                    <Styled.submenuTitle>Fulfillment</Styled.submenuTitle>

                    <SubmenuItem
                      label="국내 출고"
                      desc="스마트스토어, 쿠팡, 카페24 등 국내출고"
                      path="/fulfillment/domestic"
                      badges={["B2B", "B2C"]}
                      Icon={<SubmenuTruckIcon width={28} height={28} />}
                    />

                    <SubmenuItem
                      label="해외 출고"
                      desc="전세계 → 한국, 항공운임 견적의뢰"
                      path="/fulfillment/overseas"
                      badges={["B2B", "B2C"]}
                      Icon={<SubmenuInternationalIcon width={28} height={28} />}
                    />

                    <SubmenuItem
                      label="이벤트 출고"
                      desc="해운/항공사별 스케줄 조회"
                      path="/fulfillment/event"
                      badges={["B2B", "B2C"]}
                      Icon={<SubmenuSurpriseIcon width={28} height={28} />}
                    />
                  </ul>

                  <MobileDivide />

                  <ul onClick={handleDrawerClose}>
                    <Styled.submenuTitle>Trade Management</Styled.submenuTitle>

                    <SubmenuItem
                      label="대금결제(T/T)"
                      desc="은행 방문없이 온라인 무역대금 이체"
                      path="/tt"
                      Icon={<SubmenuGiveMoneyIcon width={28} height={28} />}
                    />
                  </ul>
                </MenuItem>

                <MenuItem
                  label="기술"
                  onSelect={handleMenuSelect}
                  selected={activeMenuLabel === "기술"}
                >
                  <ul onClick={handleDrawerClose}>
                    <SubmenuItem
                      label="오픈 API"
                      desc="발주, 운송 등 Open API 문서 제공"
                      path="https://developers.ship-da.com/" // TODO: 새 탭으로 열면 어떨지 문의 중.
                      Icon={<SubmenuCodeIcon width={28} height={28} />}
                    />
                  </ul>
                </MenuItem>

                <MenuItem
                  label="고객지원"
                  onSelect={handleMenuSelect}
                  selected={activeMenuLabel === "고객지원"}
                >
                  <ul onClick={handleDrawerClose}>
                    <SubmenuItem
                      label="가이드"
                      desc="이용방법, 용어 및 프로세스, FAQ"
                      path="/support/guide"
                      Icon={<SubmenuBookIcon width={28} height={28} />}
                    />

                    <SubmenuItem
                      label="공지사항"
                      desc="서비스/정책 주요사항 안내"
                      path="/support/notice"
                      Icon={<SubmenuAnnouncementIcon width={28} height={28} />}
                    />

                    <SubmenuItem
                      label="동영상"
                      desc="서비스 및 상세프로세스 영상"
                      path="/support/onlinelecture"
                      Icon={<SubmenuPlayerIcon width={28} height={28} />}
                    />
                  </ul>
                </MenuItem>
              </ul>
            </nav>

            {loggedIn && (
              <Logout buttonInfo={{ size: "normal", width: "100%" }} />
            )}

            <XMarkDefaultIcon
              width={32}
              height={32}
              color={COLOR.grayScale_700}
              onClick={() => setIsVisibleDrawer(false)}
              className="x-mark-default-icon"
            />
          </Styled.mobileAuthAndMenuContainer>
        </Styled.mobileDrawerContainer>
      )}
    </>
  );
}
