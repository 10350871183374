import React, { useState } from "react";
import Image from "next/image";

import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import Modal from "@sellernote/_sds-v2/src/components/Modal";

import { Trans, useTranslation } from "../../../../utils/i18n";

import Styled from "./index.styles";

export default function Complete({ hasInvitee }: { hasInvitee: boolean }) {
  const { t } = useTranslation(["team"]);

  const { isMobile } = useCheckIsMobile();

  const [showsAccessibleDefaultAuthority, setShowsAccessibleDefaultAuthority] =
    useState(false);

  return (
    <Styled.completeContainer>
      <Styled.completeTitle>
        {hasInvitee ? (
          <Trans i18nKey="team:팀_생성_완료_초대_발송_안내" />
        ) : (
          <>{t("팀_생성_완료")}</>
        )}
      </Styled.completeTitle>

      <Image
        src={"/assets/images/mypage/account/complete-illust.svg"} // PNG 나 WEBP 사용 시 글줄 부분이 많이 깨져보여 예외적으로 svg 로 사용
        alt="logo"
        width={isMobile ? 219.7 : 366}
        height={isMobile ? 120 : 200}
      />

      <Styled.completeDesc>
        {hasInvitee ? (
          <Trans i18nKey="team:팀_생성_권한부여_안내" />
        ) : (
          <Trans i18nKey="team:팀_생성_멤버관리_안내" />
        )}
      </Styled.completeDesc>

      <Modal
        uiType="titleAndBody"
        active={showsAccessibleDefaultAuthority}
        title={t("팀_생성_기본권한_모달_타이틀")}
        body={<Trans i18nKey="team:팀_생성_기본권한_모달_리스트" />}
        onClose={() => {
          setShowsAccessibleDefaultAuthority(false);
        }}
        actionPositive={{
          label: t("팀_생성_기본권한_모달_확인_버튼"),
          handleClick: () => {
            setShowsAccessibleDefaultAuthority(false);
          },
        }}
      />
    </Styled.completeContainer>
  );
}
