import { UserInfo } from "../../types/common/user";
import { getUnixTime } from "../../utils/common/date";
import { forwardingUserTypeDict } from "../../utils/forwarding/user";

import { APP_REGION } from "../../constants";

/**
 * 커스터마이징 된 채널 IO버튼에 적용하는 클래스 선택자 이름
 * - 이 className이 적용된 요소 클릭시 채널톡 메시지창이 열림
 */
const CHANNEL_IO_CUSTOM_LAUNCHER_SELECTOR_CLASS_NAME =
  APP_REGION === "KR" ? "custom-channel-io-button" : "";

const CHANNEL_IO_COMMON_BOOT_OPTION = (() => {
  /**
   * dev용 채널톡이 셋팅된 경우도 있으므로 테스트가 필요하면 해당 pluginKey를 받아서 env로 추가해서 테스트하면 됨(23.03.27 현재 KR쉽다쪽만 테스트용 채널톡이 있음)
   * SG쉽다에서는 채널톡을 사용하지 않음
   */
  const pluginKey = process.env.NEXT_PUBLIC_CHANNEL_IO_KEY;

  if (!pluginKey) return null;

  return {
    pluginKey,

    // 채널톡 메시지창을 여는 버튼을 커스텀하기 위해 사용 (참고: https://developers.channel.io/reference/web-customization-kr)
    hideChannelButtonOnBoot: true,
    customLauncherSelector: `.${CHANNEL_IO_CUSTOM_LAUNCHER_SELECTOR_CLASS_NAME}`,
  };
})();

function openChannelIOChatModal() {
  if (typeof window === "undefined") return;

  // ChannelIO에서 타입 제공 하지 않아 any로 처리
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const channelIO = (window as any).ChannelIO;

  if (channelIO) {
    channelIO("showMessenger");
  }
}

/**
 * 현재 쉽다/브링굿즈에만 적용 가능
 * 로그인한 유저의 채널IO user DB와 연결 해줌 (기존 DB에 없으면 추가, 있으면 연결)
 *
 * <업데이트 관련>
 * boot로 업데이트하는 값만 수정됨 (업데이트 하지 않는 기존 값은 유지됨)
 *
 * <쿠키관련>
 * - 쿠키를 지우지 않으면 로그아웃 했다고 해도, 세션기간(30분)동안 직전에 로그인 한 유저로 채널IO로 이야기하는 것으로 됨
 * - 로그아웃시 ch-session-<우리 채널IO ID>(ex. ch-session-17209) 쿠키를 삭제하면 해결되지면, 이렇게 하면 유저가 로그아웃시 새로운 유저가 인식되므로 MAU가 예상보다 높게나올 수 있다고 함
 */
function setUserInfoToChannelIO({
  userInfo,
  onSuccess,
}: {
  userInfo: UserInfo | undefined;
  onSuccess?: (user?: { alert: number }) => void;
}) {
  // ChannelIO에서 타입 제공 하지 않아 any로 처리
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const channelIO = (window as any).ChannelIO;

  if (!(CHANNEL_IO_COMMON_BOOT_OPTION && channelIO)) return;

  channelIO(
    "boot",
    {
      memberId: userInfo?.accountId,
      profile: {
        name: userInfo?.name,
        company: userInfo?.company,
        createdAt: userInfo?.createdAt
          ? getUnixTime(new Date(userInfo.createdAt), "milliseconds")
          : undefined,
        type: forwardingUserTypeDict[userInfo?.type || ""],
        forwardingManagerName: userInfo?.forwardingManager?.name,
        fullfillmentManagerName: userInfo?.fullfillmentManager?.name,
        email: userInfo?.email,
        mobileNumber: userInfo?.phone,
        memberId: userInfo?.accountId,
      },

      // 업데이트되면 안 되는 설정이므로 마지막에 추가
      ...CHANNEL_IO_COMMON_BOOT_OPTION,
    },
    (error?: unknown, user?: { alert: number }) => {
      if (!error && onSuccess) {
        onSuccess(user);
      }
    }
  );
}

/**
 * 채널톡 버튼 사용을 위한 초기화 단계에서 실행이 필요한 로직을 관리
 * 주의: 프로젝트 라이프사이클에서 한번만 실행되어야하므로 반드시 'initChatButtonUI' 함수를 통해서만 실행할것
 */
function initChannelIOButtonUI(setUnreadCount: (unreadCount: number) => void) {
  // ChannelIO에서 타입 제공 하지 않아 any로 처리
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const channelIO = (window as any).ChannelIO;
  if (!channelIO) return;

  // 채널톡 버튼을 커스터마이징해서 사용할 경우 확안히지 않은 메시지 수는 별도로 표시해야함 (참고: https://developers.channel.io/reference/web-customization-kr)
  channelIO("onBadgeChanged", (unreadCount: number) => {
    setUnreadCount(unreadCount);
  });
}

export {
  openChannelIOChatModal,
  setUserInfoToChannelIO,
  initChannelIOButtonUI,
  CHANNEL_IO_COMMON_BOOT_OPTION,
  CHANNEL_IO_CUSTOM_LAUNCHER_SELECTOR_CLASS_NAME,
};
