import React from "react";

import Modal from "@sellernote/_sds-v2/src/components/Modal";

import { useTranslation } from "../../../../utils/i18n";

import { NewTeamInfo, TitleAndDesc } from "./index";

export default function ModalForDesktop({
  step,
  newTeamInfo,
  opensCreateTeam,
  titleAndDesc,
  form,
  handleTeamCreate,
  handleAccountSwitch,
  moveStep,
  close,
}: {
  step: number;
  newTeamInfo: NewTeamInfo;
  opensCreateTeam: boolean;
  titleAndDesc: TitleAndDesc;
  form: JSX.Element;
  moveStep: (step: number) => void;
  handleTeamCreate: () => void;
  handleAccountSwitch: () => void;
  close: () => void;
}) {
  const { t } = useTranslation(["team"]);

  const actionButtonInfo = (() => {
    if (step === 1)
      return {
        actionPositive: {
          label: t("팀_생성_다음_버튼"),
          handleClick: () => moveStep(2),
          disabled: !newTeamInfo.name,
        },
      };

    if (step === 2)
      return {
        actionPositive: {
          label: t("팀_생성_완료_버튼"),
          handleClick: handleTeamCreate,
        },
      };

    return {
      actionPositive: {
        label: t("팀_생성_바로가기_버튼"),
        handleClick: handleAccountSwitch,
      },
    };
  })();

  return (
    <>
      <Modal
        uiType="formInput"
        active={opensCreateTeam}
        width="960px"
        height="720px"
        onClose={close}
        body={form}
        {...titleAndDesc}
        {...actionButtonInfo}
      />
    </>
  );
}
