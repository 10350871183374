import { GtmEvent } from "@sellernote/_shared/src/types/common/common";
import { UserInfo } from "@sellernote/_shared/src/types/common/user";
import { BidServiceType } from "@sellernote/_shared/src/types/forwarding/bid";

import { toFormattedDate } from "./date";

/**
 * GTM으로 보내는 유저정보
 */
type UserInfoForGTM =
  | {
      login_check: "O";
      user_id: UserInfo["id"];
      user_type: UserInfo["type"];
      corpSizeType: UserInfo["corpSizeType"];
      teamId: UserInfo["teamId"];
      bidCount: UserInfo["bidCount"];
      inProgressBidCount: UserInfo["inProgressBidCount"];
      searchCount: UserInfo["searchCount"];
    }
  | {
      login_check: "X";
    };

/**
 * gtm으로 전송하는 dataLayer에 user 정보를 추가
 */
function setUserInfoToGTMDataLayer(userInfo?: UserInfo) {
  if (typeof window === "undefined") {
    return;
  }

  const existedDataLayer = (window as any).dataLayer;
  const dataLayer = existedDataLayer || [];
  if (!existedDataLayer) {
    (window as any).dataLayer = dataLayer;
  }

  const newUserInfo: UserInfoForGTM = userInfo
    ? {
        login_check: "O",
        user_id: userInfo.id,
        user_type: userInfo.type,
        corpSizeType: userInfo.corpSizeType,
        teamId: userInfo.teamId,
        bidCount: userInfo.bidCount,
        inProgressBidCount: userInfo.inProgressBidCount,
        searchCount: userInfo.searchCount,
      }
    : {
        login_check: "X",
      };

  /**
   * <유의사항>
   * - dataLayer에 유저정보가 중복으로 들어갈 것을 우려하여 해당 배열요소를 업데이트하는 식으로 해서는 안 된다.
   * - 동일 index의 배열요소를 업데이트하는 방식을 사용하면, GTM에서 데이터 변경 인지를 못한다.
   * - 항상 push로 업데이트 해야한다
   */
  dataLayer.push(newUserInfo);
}

function addEventToGTMDataLayer(event: GtmEvent) {
  if (typeof window === "undefined" || !event) {
    return;
  }

  const existedDataLayer = (window as any).dataLayer;
  const dataLayer = existedDataLayer || [];
  if (!existedDataLayer) {
    (window as any).dataLayer = dataLayer;
  }

  dataLayer.push(event);
}

/**
 * gtm 트래킹용 service type을 마케팅파트에서 원하는 이름으로 셋팅해주기 위함
 */
function getServiceNameForGtm(serviceType: BidServiceType) {
  switch (serviceType) {
    case "general": {
      return "realquote";
    }
    case "oceanTicket": {
      return "oceanticket";
    }
    case "consolidation": {
      return "cons";
    }
    default: {
      return "";
    }
  }
}

function sendSignUpCompleteGTMEvent({
  createdAt,
  userId,
}: {
  createdAt: string | undefined;
  userId: number | undefined;
}) {
  addEventToGTMDataLayer({
    event: "signup_complete",
    user_id: userId,
    signup_date: createdAt
      ? toFormattedDate(createdAt, "YYYY-MM-DD HH:mm", true)
      : "",
    signup_week: createdAt ? toFormattedDate(createdAt, "YYYY-ww", true) : "",
  });
}

export {
  getServiceNameForGtm,
  setUserInfoToGTMDataLayer,
  addEventToGTMDataLayer,
  sendSignUpCompleteGTMEvent,
};
