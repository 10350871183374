import { Trans } from "../../../../../utils/i18n";

import { MobileTabData } from ".";

const MOBILE_TAB_LIST: MobileTabData[] = [
  {
    tabValue: "all",
    title: <Trans i18nKey="common-new:담당자별연락처_전체" />,
  },
  {
    tabValue: "newcomerGuide",
    title: <Trans i18nKey="common-new:담당자별연락처_신규상담" />,
  },
  {
    tabValue: "management",
    title: <Trans i18nKey="common-new:담당자별연락처_운영" />,
  },
];

export default MOBILE_TAB_LIST;
