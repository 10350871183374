import { ObjectKeys } from "../../types/common/object";

/**
 * `Object.keys(obj)` 로 했을때 type지원이 안 되는 단점을 보완한 함수
 * - 기능은 동일하고 Typescript지원이 되는 점만 다르다.
 * @param obj
 */
function getObjectKeyList<T extends object>(obj: T) {
  return Object.keys(obj) as (keyof T)[];
}

/**
 * `Object.entries(obj)` 로 했을때 type지원이 안되는 단점을 보완한 함수
 * - 기능은 동일하고 Typescript지원이 되는 점만 다르다.
 *
 * @example
 * const object = {
 *   one: 1,
 *   two: 2,
 *   three: 3,
 * }
 *
 * @use
 * getObjectEntries(object)
 *
 * @returns Array<['one' | 'two' | 'three', 1 | 2 | 3]>
 */
function getObjectEntries<Type extends Record<PropertyKey, unknown>>(
  obj: Type
): [ObjectKeys<Type>, Type[ObjectKeys<Type>]][] {
  return Object.entries(obj) as [ObjectKeys<Type>, Type[ObjectKeys<Type>]][];
}

export { getObjectKeyList, getObjectEntries };
