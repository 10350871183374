import React, { useState } from "react";

import { APP_REGION } from "@sellernote/_shared/src/constants";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import BottomSheet from "@sellernote/_sds-v2/src/components/bottomSheet/BottomSheet";
import TextButton from "@sellernote/_sds-v2/src/components/button/TextButton";
import Modal from "@sellernote/_sds-v2/src/components/Modal";
import FourChevronRightIcon from "@sellernote/_sds-v2/src/components/svgIcons/FourChevronRightIcon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { useTranslation } from "../../../../utils/i18n";

import ManagerInfoModalBody from "./ManagerInfoModalBody";
import SalesManagerGuide from "./SalesManagerGuide";
import Styled from "./index.styles";

export default function SalesMangerInfo() {
  const [showsManagerModal, setShowsManagerModal] = useState(false);
  const [showsManagerModalForMobile, setShowsManagerModalForMobile] =
    useState(false);

  const { t } = useTranslation(["common-new"]);

  const { isMobile } = useCheckIsMobile();

  return (
    <Styled.container>
      <Styled.salesMangerInfo>
        <TextButton
          theme="white"
          fontSize={14}
          label={t("common-new:푸터_담당자별연락처")}
          handleClick={() =>
            isMobile
              ? setShowsManagerModalForMobile(true)
              : setShowsManagerModal(true)
          }
          iconInfo={{
            Icon: FourChevronRightIcon,
            position: "right",
          }}
          btnAreaPadding={
            isMobile
              ? {
                  top: 8,
                  bottom: 8,
                  right: 8,
                }
              : undefined
          }
          className="sales-manager-info-button"
        />
      </Styled.salesMangerInfo>

      {APP_REGION === "KR" && (
        <SalesManagerGuide
          textColor={COLOR.grayScale_400}
          buttonColor={COLOR.wh}
          isBullet={true}
        />
      )}

      <Modal
        uiType="formInput"
        title={t("common-new:담당자별연락처_제목")}
        active={showsManagerModal}
        onClose={() => setShowsManagerModal(false)}
        width="960px"
        height="526px"
        body={<ManagerInfoModalBody />}
      />

      {showsManagerModalForMobile && (
        <BottomSheet
          title={t("common-new:담당자별연락처_제목")}
          mainButtonInfo={{
            label: t("common-new:담당자별연락처_닫기_버튼"),
            onClick: () => {
              setShowsManagerModalForMobile(false);
            },
          }}
          opensBottomSheet={showsManagerModalForMobile}
          setOpensBottomSheet={() => setShowsManagerModalForMobile(true)}
          onClose={() => setShowsManagerModalForMobile(false)}
        >
          <ManagerInfoModalBody />
        </BottomSheet>
      )}
    </Styled.container>
  );
}
